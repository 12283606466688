import React from 'react';
import { withPrismicUnpublishedPreview} from 'gatsby-plugin-prismic-previews';
import Layout from "components/Layout";
import styled from "styled-components";
import { P1 } from '@components/styled';
import { media } from "@styles/theme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  z-index: 999999;
  padding: 100px;
  width: 100%;
  height: 100vh;

  @media ${media.sm} {
    padding: 50px;
  }

  @media ${media.s} {
    padding: 20px;
  }

  svg {
    min-width:200px;

    @media ${media.sm} {
      height: auto;
      width: 100%;
    }
  }

  ${P1} {
    text-align: center;
  }
`

const NotFoundPage = () => {
  return (
    <Layout location={"404/"}>
      <Container>
      <svg width="626" height="304" viewBox="0 0 626 304" fill="none" xmlns="http://www.w3.org/2000/svg" width="100%">
        <path d="M113.5 208V256H143V207.25M113.5 83.5L33.5 180.5H93.75M174 208V180.5H143M143 155.5V47H108.5L2 180.5V208H57.75H85.625" stroke="#6E6E6E" strokeWidth="3" strokeLinecap="round"/>
        <path d="M113.5 83.5V214.5" stroke="#707070" strokeWidth="3" strokeLinecap="round"/>
        <path d="M563 84.5L483.5 180.5H563M624 208V180.5H593M593 155.5V47H558.5L452 180.5V208H563.5V256H593V207.25" stroke="#6E6E6E" strokeWidth="3" strokeLinecap="round"/>
        <path d="M563 84.5002L563 180" stroke="#707070" strokeWidth="3" strokeLinecap="round"/>
        <path d="M302.06 8.44403C273.937 10.4555 247.456 22.4466 227.382 42.2588C207.309 62.0709 194.964 88.4025 192.567 116.513C190.171 144.624 197.883 172.667 214.313 195.595C230.743 218.522 254.813 234.828 282.189 241.576C309.565 248.324 338.45 245.072 363.643 232.404C388.836 219.736 408.682 198.486 419.609 172.478C430.536 146.47 431.825 117.413 423.245 90.5378C414.666 63.6629 396.78 40.7355 372.809 25.8831" stroke="#6E6E6E" strokeWidth="3"/>
        <path d="M310.498 202.715C293.019 202.715 276.073 196.693 262.509 185.662C248.944 174.631 239.589 159.263 236.016 142.142C232.443 125.022 234.869 107.192 242.887 91.6506C250.906 76.1093 264.027 63.8043 280.045 56.8042C296.063 49.8041 314.001 48.5358 330.843 53.2126C347.685 57.8893 362.405 68.2259 372.528 82.4845C382.65 96.7432 387.558 114.054 386.426 131.508C385.294 148.961 378.192 165.492 366.313 178.322" stroke="#6E6E6E" strokeWidth="3"/>
        <path d="M379.175 222.932L450.38 292.328C456.986 298.767 467.747 298.788 474.381 292.375V292.375C481.012 285.964 480.992 275.564 474.337 269.176L445.857 241.845L403.111 199.072" stroke="#6E6E6E" strokeWidth="3"/>
      </svg>


        <P1>Sorry. This page is not found.</P1>
      </Container>
    </Layout>
  )
}

export default withPrismicUnpublishedPreview(NotFoundPage)